<template>
    <div>
		
		<v-row v-if="isLoading">
			<v-col cols=12>
				<v-skeleton-loader
					type="card-avatar"
				></v-skeleton-loader>
			</v-col>
			<v-col cols=9>
				<v-skeleton-loader
					type="article, article, actions"
				></v-skeleton-loader>
			</v-col>
			<v-col cols=3>
				<v-skeleton-loader
					type="article, article, actions"
				></v-skeleton-loader>
			</v-col>
			<v-col cols=9>
				<v-skeleton-loader
					type="article, article, actions"
				></v-skeleton-loader>
			</v-col>
			<v-col cols=3>
				<v-skeleton-loader
					type="article, article, actions"
				></v-skeleton-loader>
			</v-col>
		</v-row>

		<v-toolbar dark extended extension-height="100" v-if="!isLoading && reference.is_disabled == 0">
			<v-btn icon :to="{ name: 'StartPage' }">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>

			<v-toolbar-title>{{ reference.ter_name }}</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-chip color="green accent-3" v-if="reference.isConnectivity && !(saveResolution && saveResolution.id)" label>Заявка в работе</v-chip>
			<v-chip color="yellow" style="color:#000" v-if="reference.isConnectivity && (saveResolution && saveResolution.id)" label>Получено решение по заявке</v-chip>

			<v-btn color="teal accent-4" @click="connectivity()" :loading="btnConnectivity" v-if="!reference.isConnectivity && reference.countConnectivity < 3">Принять в работу</v-btn>
			<v-chip color="red accent-4" v-if="reference.countConnectivity >= 3 && !(reference.isConnectivity && !(saveResolution && saveResolution.id))" class="ml-2" label>Не доступна</v-chip>

			<template v-slot:extension>
				<span class="hidden-mobile">
					Перечень критериев экспертной оценки общего объема контрольных цифр приема по профессиям, специальностям и (или) укрупненным группам профессий, специальностей 
					для обучения по образовательным программам среднего профессионального образования за счет бюджетных ассигнований бюджетов субъектов Российской Федерации 
					в рамках участия субъектов Российской Федерации в федеральном проекте «Профессионалитет»
				</span>
			</template>
		</v-toolbar>

		<v-alert v-if="reference.is_disabled==1" border="left" colored-border color="red accent-4" elevation="2">
			<v-btn icon :to="{ name: 'StartPage' }">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			Доступ временно ограничен
		</v-alert>

		<div class="wrapper-expertise mt-5" v-if="!isLoading">
			<div class="s--row-stage" v-for="stage,k in stages" :key="k">
				<v-row>
					<v-col lg=9 cols=12>
						<v-card min-height="100">
							<v-card-text>
								<span class="s--stage-title">{{ stage.id }}.</span>
								<span v-html="stage.name" class="s--stage-title"></span>
							</v-card-text>
						</v-card>
						<v-card class="mt-3" 
							:disabled="( !reference.isConnectivity || (saveResolution && saveResolution.id) ? true : false )" 
							:dark="!reference.isConnectivity" 
							:color="((answers[ri] && comments[ri]) || (saveResolution && saveResolution.id) || !(ri <= 3) ? 'light-green lighten-5' : (reference.isConnectivity ? 'orange lighten-4' : ''))"
							v-for="(row, ri) in stages_rows_answers[stage.id]" 
							:key="ri"
						>
							<div class="pl-5 pt-1">
								<v-radio-group row v-model="answers[ri]" hide-details :disabled="!(ri <= 3)">
									<v-radio :label="item.label" @change="saveLocalAnswers()" :disabled="(!reference.isConnectivity ? true : false)" :value="item.id" hide-detalic v-for="item, i in row" :key="i"></v-radio>
								</v-radio-group>

								<v-textarea
									filled
									label="Комментарий по данному решению"
									rows=2
									auto-grow
									clearable
									hide-details
									@change="saveLocalComments()"
									v-model.trim="comments[ri]"
									class="mt-2 mr-5"
									v-if="ri <= 3"
								></v-textarea>
								<div v-else>&nbsp;</div>
							</div>
						</v-card>
						<v-expansion-panels class="mt-3" elevation="0">
							<v-expansion-panel>
								<v-expansion-panel-header>
									<h4>Методика оценки</h4>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<span v-html="stage.methodology"></span>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-col>

					<v-col lg=3 cols=12>
						<v-card min-height="300" v-if="k < 3">
							<v-subheader class="mb-0 pb-0">
								<div style="display:flex; justify-content: space-between; width:100%">
									<span>Список файлов:</span>
									<v-chip label small>{{ getDocuments(stage.id).length }}</v-chip>
								</div>
							</v-subheader>
							<div style="max-height:208px; overflow-x:auto" class="scroll-style">
								<v-list nav dense class="mt-0 mb-0 pt-0">
									<v-list-item small v-for="item,i in getDocuments(stage.id)" :key="i" :href="item.file_link" target="_blank">
										<v-list-item-content>
											<v-list-item-title>
												<v-tooltip top>
													<template v-slot:activator="{ on, attrs }">
														<span v-bind="attrs" v-on="on" >
															<v-chip label x-small>{{ item.file_type }}</v-chip> {{ i+1 }}. {{ item.comment }}
														</span>
													</template>
													<span>
														{{ item.comment }}
													</span>
												</v-tooltip>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</div>
							<v-btn block text class="mt-2" @click="downloadAll( getDocuments(stage.id), stage.id )" :loading="btnDownloadAll == stage.id" v-if="getDocuments(stage.id).length > 1">Скачать все</v-btn>
						</v-card>
					</v-col>
				</v-row>
				<v-divider class="mt-7 mb-1" v-if="k != stages.length-1"></v-divider>
				<v-divider class="mt-1 mb-7" v-if="k != stages.length-1"></v-divider>
			</div>

			<div class="pb-10 d-flex justify-space-between">
				<div>
					<v-btn color="primary" @click="resolution()" :disabled="(saveResolution && saveResolution.id ? true : false)" v-if="reference.isConnectivity" x-large  :loading="resolutionBtnSubmit">Согласовать экспертное заключение</v-btn>
					<v-btn color="warning" @click="resolutionDialog()" :disabled="(saveResolution && saveResolution.id ? true : false)" v-if="reference.isConnectivity" x-large class="ml-2">Согласовать с замечаниями</v-btn>
				</div>
				<v-btn color="primary" @click="compliteReport()" :loading="btnCompliteReport" v-if="saveResolution && saveResolution.id" x-large class="ml-2">Сформировать отчет</v-btn>
			</div>

			<v-divider class="mb-5"></v-divider>

			<div class="pb-10 d-flex justify-space-between">
				<v-btn :to="{ name: 'StartPage' }" color="primary"><v-icon>mdi-arrow-left</v-icon> Вернуться к заявкам</v-btn>
			</div>

			<v-dialog transition="dialog-bottom-transition" max-width="720" v-model="resolutionDialogStatus">
				<v-card>
					<v-toolbar color="primary" dark>
						<v-toolbar-title>
							Согласовать с замечаниями
						</v-toolbar-title>
					</v-toolbar>
					<v-card-text class="pt-5">
						<v-textarea label="Укажите комментарий" v-model="messageRemarks" outlined rows="4" hint="Что необходимо изменить?" persistent-hint></v-textarea>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions class="justify-end">
						<v-btn text @click="resolutionDialogStatus = false" class="mr-2">Закрыть</v-btn>
						<v-btn @click="resolution(true)" color="primary" :loading="resolutionBtnSubmit">Согласовать</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isLoading: false,
			btnConnectivity: false,

			documents: [],
			stages: [],
			reference: {},
			stages_rows_answers: {},

			answers: {},
			comments: {},

			saveResolution: {},
			btnCompliteReport: false,
			btnDownloadAll: 0,
			resolutionDialogStatus: false,

			resolutionBtnSubmit: false,
			messageRemarks: '',
		}
	},
	methods: {
		// ? Очистить кеш 
		cleanCache() {
			let _key = 'comments_' + this.$route.params.id
			localStorage.removeItem(_key)
		},

		// ? Сохранить комментарии в локальном кеше 
		saveLocalComments(){
			if ( !this.isLoading ) {
				let _key = 'comments_' + this.$route.params.id
				localStorage.setItem(_key, JSON.stringify(this.comments))
			}
		},

		// ? Очистить кеш 
		cleanCacheAnswers() {
			let _key = 'answers_' + this.$route.params.id
			localStorage.removeItem(_key)
		},

		// ? Сохранить ответы в локальном кеше 
		saveLocalAnswers(){
			setTimeout(() => {
				if ( !this.isLoading ) {
					let _key = 'answers_' + this.$route.params.id
					localStorage.setItem(_key, JSON.stringify(this.answers))
				}
			},200)
		},

		// ? Скачать все документы 
		downloadAll( documents, id ){
			this.btnDownloadAll = id
			documents = documents.map((x) => {
				return x.file_id_download
			}).join(",")

			this.$http.get(`expert/download-zip/?documents=${documents}`, {responseType: 'blob'}).then(response => {
				if ( response.data.status !== false ) {
					let blob = new Blob([response.data], { type: 'application/zip' });
					let url = window.URL.createObjectURL(blob);
					let link = document.createElement('a');
					link.href = url;
					link.download = `documents_${new Date().getMilliseconds()}.zip`;
					document.body.appendChild(link);
					link.click();
					link.remove();
					this.btnDownloadAll = 0;
				} else {
					this.btnDownloadAll = 0;
					this.$toast.error("Что-то пошло не так - сервер не ответил");
				}
			}).catch((err) => {
				this.btnDownloadAll = 0;
				this.$toast.error("Что-то пошло не так - сервер не ответил");
				this.$toast.error(err);
			});
		},

		// ? Сформировать отчет
		compliteReport() {
			this.btnCompliteReport = true
			this.$http.post(`expert/report/${this.$route.params.id}`)
			.then((response) => {
				if ( response.data.status ) {
					this.btnCompliteReport = false

					var link = document.createElement('a');
					link.target = '_blank';
					link.href = response.data.file_link;
					document.body.appendChild(link);
					link.click();
				}
			}).catch((err) => {
				this.$toast.error("Что-то пошло не так - сервер не ответил");
				this.$toast.error(err);
			});
		},

		// ? Сохранить ответы 
		resolution(remark=false) {
			if ( !this.answers[1] || !this.answers[2] || !this.answers[3] ) {
				this.$toast.error("Вы не ответили на все критерии оценивания");
				return false;
			}
			if ( !this.comments[1] || !this.comments[2] || !this.comments[3] ) {
				this.$toast.error("Вы не прокомментировали все критерии оценивания");
				return false;
			}
			if (remark && (!this.messageRemarks || this.messageRemarks == "")) {
				this.$toast.error("Вы не заполнили поле с комментарием");
				return false;
			}
			
			this.$confirm.q( `Вы подтверждаете сохранение ответов и завершение работы по данной заявке #${this.reference.registration_number}?`, () => {
				this.resolutionBtnSubmit = true;
				this.$http.post(`expert/resolution/${this.$route.params.id}`, {
					form: 			 this.answers,
					comments: 		 this.comments,
					messageRemarks: remark ? this.messageRemarks : null,
				})
				.then((response) => {
					if ( response.data.status ) {
						this.$toast.open("Ответ получен.");
						this.getExpertise()
						this.cleanCache()
						this.cleanCacheAnswers()
						this.resolutionDialogStatus = false;
					} else {
						if (response.data.reason) {
							this.$toast.error(response.data.reason);
						} else {
							this.$toast.error("Что-то пошло не так");
						}
					}
					this.btnConnectivity = false
				})
				.catch(() => {
					this.btnConnectivity = false
					this.$toast.error("Что-то пошло не так - сервер не ответил");
				});
				this.resolutionBtnSubmit = false;
			});
		},

		resolutionDialog(){
			if ( !this.answers[1] || !this.answers[2] || !this.answers[3] ) {
				this.$toast.error("Вы не ответили на все критерии оценивания");
				return false;
			}
			if ( !this.comments[1] || !this.comments[2] || !this.comments[3] ) {
				this.$toast.error("Вы не прокомментировали все критерии оценивания");
				return false;
			}

			this.resolutionDialogStatus = true;
		},

		// ? Взять заявку в работу
		connectivity() {
			this.btnConnectivity = true
			this.$http.post(`expert/connectivity/${this.$route.params.id}`)
				.then((response) => {
					if ( response.data.status ) {
						this.$toast.open("Заявка принята в работу");
						this.getExpertise()
					} else {
						if (response.data.reason) {
							this.$toast.error(response.data.reason);
						} else {
							this.$toast.error("Что-то пошло не так");
						}
					}
					this.btnConnectivity = false
				})
				.catch(() => {
					this.btnConnectivity = false
					this.$toast.error("Что-то пошло не так - сервер не ответил");
				});
		},

		getDocuments(stage_id){
			return this.documents.filter((e) => e.stage_id == stage_id)
		},

		getExpertise() {
			this.isLoading = true
			this.$http.get(`expert/expertise/${this.$route.params.id}`)
				.then((response) => {
					if ( response.data.status ) {
						this.stages = response.data.stages
						this.reference = response.data.reference
						this.documents = response.data.documents
						this.stages_rows_answers = response.data.stages_rows_answers

						this.saveResolution = response.data.resolution

						if ( this.saveResolution && this.saveResolution.id ) {
							Object.keys(this.saveResolution).map((key) => {
								if ( key[0] == "a" ) {
									this.answers[key.replace("a","")] = this.saveResolution[key]
								}
								if ( key[0] == "c" ) {
									this.comments[key.replace("c","")] = this.saveResolution[key]
								}
							})
						}

						if (response.data.firpo_render) {
							Object.keys(response.data.firpo_render).map((key) => {
								if ( key[0] == "a" ) {
									this.answers[key.replace("a","")] = response.data.firpo_render[key]
								}
							})
						}

						setTimeout(() => {
							this.isLoading = false
						}, 100)
					}
				})
				.catch(() => {
					this.$toast.error("Что-то пошло не так - сервер не ответил");
				});
		}
	},
	created() {
		let _key = 'comments_' + this.$route.params.id
		let _comments = localStorage.getItem(_key)

		if ( _comments != null ) {
			_comments = JSON.parse(_comments)
			Object.keys(_comments).map((key) => {
				this.comments[key] = _comments[key]
			})
		}

		_key = 'answers_' + this.$route.params.id
		let _answers = localStorage.getItem(_key)

		if ( _answers != null ) {
			_answers = JSON.parse(_answers)
			Object.keys(_answers).map((key) => {
				this.answers[key] = parseInt(_answers[key])
			})
		}

		this.getExpertise()
	}
}
</script>

<style>
.scroll-style::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.scroll-style::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.scroll-style::-webkit-scrollbar-thumb {
  background: #3F51B5;
  border: 0px none #3F51B5;
  border-radius: 50px;
}
.scroll-style::-webkit-scrollbar-thumb:hover {
  background: #3F51B5;
}
.scroll-style::-webkit-scrollbar-thumb:active {
  background: #000000;
}
.scroll-style::-webkit-scrollbar-track {
  background: #fafafa;
  border: 0px none #3F51B5;
  border-radius: 50px;
}
.scroll-style::-webkit-scrollbar-track:hover {
  background: #fafafa;
}
.scroll-style::-webkit-scrollbar-track:active {
  background: #3F51B5;
}
.scroll-style::-webkit-scrollbar-corner {
  background: transparent;
}
</style>